import React from "react";
import ReactDOM from "react-dom";
import SnakeGame from "Main";

console.log("I wonder why there is a man seeing this sentence.");

ReactDOM.render(
  <SnakeGame/>,
  document.getElementById("root")
);
